import { BanknotesIcon, BuildingStorefrontIcon, PhotoIcon, UserGroupIcon, GiftIcon } from '@heroicons/react/24/outline'
import DollarIcon from 'shared/icons/DollarIconOld'
import FrameIcon from 'shared/icons/FrameIcon'
import MeterIcon from 'shared/icons/MeterIcon'

export type MenuItemHrefType = string | ((username?: string) => string)
export type MenuItemRegexType = RegExp | ((username?: string) => RegExp)

export type MenuItemType = {
  label: string
  href?: MenuItemHrefType
  icon: JSX.Element
  regex?: MenuItemRegexType
  isHide?: boolean
}

export type SubMenuItemType = Omit<MenuItemType, 'icon'> & {
  icon?: JSX.Element
}

export type AppMenuItem = MenuItemType & {
  subMenu?: Array<SubMenuItemType>
}

export const CollectorMenu: Array<AppMenuItem> = [
  {
    label: 'Overview',
    href: '/collector/overview',
    regex: /\/collector\/overview/,
    icon: <MeterIcon className="h-6 w-6" />,
  },
  {
    label: 'Inventory',
    href: '/collector/inventory',
    regex: /\/collector\/inventory/,
    icon: <PhotoIcon className="h-6 w-6" />,
  },
  {
    label: 'Shared With Me',
    href: '/collector/shared-with-me',
    regex: /\/collector\/shared-with-me/,
    icon: <FrameIcon className="h-6 w-6" />,
  },
  // Note: Temporarily hide this menu item
  // {
  //   label: 'Link in Bio',
  //   href: '/link-in-bio/edit-as-collector',
  //   regex: /\/link-in-bio\/edit-as-collector/,
  //   icon: <CursorArrowRaysIcon className="h-6 w-6" />,
  // },
]

export const CreatorMenu: Array<AppMenuItem> = [
  {
    label: 'Portfolio',
    href: '/artist/storefront',
    regex: /\/artist\/storefront/,
    icon: <BuildingStorefrontIcon className="h-6 w-6" />,
  },
  {
    label: 'Inventory',
    href: '/artist/inventory',
    regex: /\/artist\/inventory/,
    icon: <PhotoIcon className="h-6 w-6" />,
  },
  {
    label: 'Sales',
    icon: <DollarIcon />,
    subMenu: [
      {
        label: 'Invoices',
        href: '/artist/invoices',
        regex: /\/artist\/invoices/,
      },
      {
        label: 'Sales Record',
        href: '/artist/sales',
        regex: /\/artist\/sales/,
      },
      {
        label: 'Testimonials',
        href: '/artist/testimonials',
        regex: /\/artist\/testimonials/,
      },
    ],
  },
  {
    label: 'Private Views',
    href: '/advisor/private-views',
    regex: /\/advisor\/private-views/,
    icon: <FrameIcon className="h-6 w-6" />,
  },
  // {
  //   label: 'Relationship',
  //   href: '/artist/community',
  //   regex: /\/artist\/community/,
  //   icon: <UserGroupIcon className="h-6 w-6" />,
  // },
  {
    label: 'Subscribers',
    icon: <UserGroupIcon className="h-6 w-6" />,
    subMenu: [
      {
        label: 'Contacts',
        href: '/crm/contacts',
        regex: /\/crm\/contacts/,
      },
      {
        label: 'Email Campaigns',
        href: '/crm/campaign',
        regex: /\/crm\/campaign/,
      },
      {
        label: 'Manage Plan',
        href: '/settings/manage-plan',
        regex: /\/settings\/manage-plan/,
      },
    ],
  },
  {
    label: 'Promotion',
    href: '/promotion-code',
    regex: /promotion-code/,
    icon: <GiftIcon className="h-6 w-6" />,
  },
]

export const AdvisorMenu: Array<AppMenuItem> = [
  {
    label: 'Invoices',
    href: '/advisor/invoices',
    regex: /\/advisor\/invoices/,
    icon: <DollarIcon />,
  },
  {
    label: 'Artist Management',
    href: '/advisor/artist-management',
    regex: /\/advisor\/artist-management/,
    icon: <UserGroupIcon className="h-6 w-6" />,
  },
  {
    label: 'Private Views',
    href: '/advisor/private-views',
    regex: /\/advisor\/private-views/,
    icon: <FrameIcon className="h-6 w-6" />,
  },
  {
    label: 'Artworks',
    href: '/advisor/artworks',
    regex: /\/advisor\/artworks/,
    icon: <PhotoIcon className="h-6 w-6" />,
  },
]

export const HenryMenu: Array<AppMenuItem> = []

export const SharedMenu: Array<AppMenuItem> = [
  {
    label: 'Rewards & Credits',
    href: `/settings/credits`,
    regex: /\/settings\/credits/,
    icon: <BanknotesIcon className="h-6 w-6" />,
  },
]
